

/* desktop.css */
@media (min-width: 800px) {
    .header {
        padding: 20px;
        flex-grow: 1;
        position: relative;
        top: 50px; /* or any other distance in pixels */
    }
    
}

/* mobile.css */
@media (max-width: 800px) {
    .header {
        padding: 10px;
        flex-grow: 1;
        position: relative;
        top: 10px; /* or any other distance in pixels */
        
    }

}

