.footer {
    position: relative;
    z-index: index;
    bottom: 0;
    display: flex;
    justify-content:center;
    padding: 16px;
}



/* desktop.css */
@media (min-width: 800px) {
    span {
        font-family: Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
        font-size: 15px !important;
        color: #0bb4aa;
    }
}

/* mobile.css */
@media (max-width: 800px) {
    span {
        font-family: Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
        font-size: 10px !important;
        color: #0bb4aa;
    }
}