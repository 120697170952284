@import url('https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap');

#homepage-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;
    object-fit:cover;
    opacity: 25%;

    user-select: none;
    pointer-events: none;

    z-index: -1;
}

.content {
    padding: 32px;
    flex-grow: 1;
}

.content.--centered {
    display: flex;
    align-items: center;
}

.centered {
    text-align: center;
}

.links {
    color: #0bb4aa;
}



/* desktop.css */
@media (min-width: 800px) {
    h1 {
        font-family: 'Rouge Script', cursive !important;
        font-size: 15vh !important;
        font-weight: 200;
    }
}

/* mobile.css */
@media (max-width: 800px) {
    h1 {
        font-family: 'Rouge Script', cursive !important;
        font-size: 9vh !important;
        font-weight: 100;
    }
}

