.quote-main {
    line-height: 0px;
}

.quote-sub {
    line-height: 50px;
}

.content {
    padding: 10px;
    flex-grow: 1;
}

.content.--centered {
    display: flex;
    align-items: center;
}

.centered {
    text-align: center ;
}

 /* desktop.css */
@media (min-width: 800px) {
    h3 {
      font-family: 'Rouge Script', cursive !important;
      font-size: 6vh !important;
      font-weight: 200 !important;
    }
  
    p {
      font-family: Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
      font-size: 2vh !important;
    }

    .quote-main {
        line-height:normal;
    }
    
    .quote-sub {
        line-height: 50px;
    }
    
    button {
        font-family: Haettenschweiler, 'Arial', sans-serif !important;
        font-size: 1em;
        padding: 0.25em 1em;
        border-radius: 0.25em;
        border: 1px solid #333;
        background-color: #fff;
        color: #333;
        margin-left: 1em;
        margin-right: 1em;
    }
    
    button:hover {
        background-color: rgb(201, 201, 201);
        color: #0bb4aa;
    }
  }
  
/* mobile.css */
@media (max-width: 800px) {
    h3 {
        font-family: 'Rouge Script', cursive !important;
        font-size: 5vh !important;
    }

    p {
        font-family: Haettenschweiler, 'Arial', sans-serif !important;
        font-size: 1em !important;
    }

    .quote-main {
        line-height: 35px;
    }
    
    .quote-sub {
        line-height: 20px;
    }

    button {
        font-family: Haettenschweiler, 'Arial', sans-serif !important;
        font-size: 1em;
        padding: 0.1em 1em;
        border-radius: 0.25em;
        border: 1px solid #333;
        background-color: #fff;
        color: #333;
        margin-left: 1em;
        margin-right: 1em;
    }
    
    button:hover {
        background-color: rgb(201, 201, 201);
        color: #0bb4aa;
    }

}